import React from "react";
import NetaCreateForm from "../../../components/Admin/Neta/NetaCreateForm";

const CreateNeta = () => {
  return (
    <div className="flex flex-col gap-4 px-4 pt-6 text-start justify-center">
      <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-gray-800 w-full">
        <div className="border-b border-stroke py-4 px-6 dark:border-strokedark">
          <h3 className="font-semibold text-black dark:text-white">
            Create Neta
          </h3>
        </div>
        <NetaCreateForm />
      </div>
    </div>
  );
};

export default CreateNeta;
