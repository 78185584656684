import { Link } from "react-router-dom";
import { useAppSelector } from "../../../store/hooks";
import { MemberType } from "../../../models";

const QuestionArea = () => {
  const { mostQuestionMember } = useAppSelector((state) => state.member);

  const totalQuestionCount = mostQuestionMember?.reduce(
    (sum: number, member: MemberType) => sum + member.num_questions,
    0
  );

  if (totalQuestionCount === 0) return null;
  return (
    <section className="question_area lg:py-10 py-8">
      <div className="container-fluid mx-auto">
        <div className="section_header pb-8 flex flex-wrap items-center justify-between gap-5">
          <h3 className="inline-block px-5 text-black xl:text-4xl lg:text-2xl md:text-xl text-lg font-medium relative before:block before:absolute before:top-1/2 before:left-0 before:w-[100px] before:h-[50px] before:bg-green-500 before:-z-10 before:-skew-x-[20deg] before:-translate-y-1/2">
            Neta Got Most Questions
          </h3>
          <Link
            to={"/questions/list"}
            className="inline-block bg-green-600 p-2 w-[200px] text-center rounded-md text-white md:text-base text-sm font-normal"
          >
            More Questions
          </Link>
        </div>
        <div className="grid 2xl:grid-cols-6 lg:grid-cols-4 md:grid-cols-3 grid-cols-2 md:gap-6 gap-3">
          {mostQuestionMember?.map((item: MemberType) => (
            <div
              key={item.id}
              className="qa_item bg-white shadow-theme_shadow px-3 py-8 rounded-md text-center group hover:scale-[1.05] transition-all"
            >
              <div className="item_thumb h-[100px]">
                <Link to={`/neta/details/${item?.id}`}>
                  <img
                    className="h-full object-contain border-2 border-green-500 mx-auto rounded-full"
                    src={
                      item.profile_pic
                        ? item.profile_pic
                        : "https://media.istockphoto.com/id/1337144146/vector/default-avatar-profile-icon-vector.jpg?s=612x612&w=0&k=20&c=BIbFwuv7FxTWvh5S3vB6bkT0Qv8Vn8N5Ffseq84ClGI="
                    }
                    alt=""
                  />
                </Link>
              </div>
              <div className="item_text pt-5">
                <Link to={`/neta/details/${item?.id}`}>
                  <h6 className="text-green-500 md:text-base text-sm font-normal line-clamp-2">
                    {item.name}
                  </h6>
                </Link>
                <Link to={`/neta/details/${item?.id}`}>
                  <span className="status inline-block text-[#838383] text-sm font-normal">
                    {item.designation}
                  </span>
                </Link>
                <Link
                  to={`/neta/details/${item?.id}`}
                  className="text-green-500 text-opacity-70 text-sm font-normal block pt-2 group-hover:text-red-400 transition-all"
                >
                  {item.num_questions} Question
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default QuestionArea;
