import { createBrowserRouter } from "react-router-dom";
import Home from './pages/Home'
import NetaList from "./pages/Neta/List";
import NewsDetails from "./pages/NewsDetails.js";
import ErrorBoundary from "./pages/ErrorPage";
import Questions from "./pages/Questions";
import NetaDetails from "./pages/Neta/Details";
import Answers from "./pages/Answers";
import Layout from "./components/Layout/Layout";
import News from "./pages/News";
import Petition from "./pages/Petition";
import PetitionDetails from "./pages/PetitionDetails";
import Registration from "./pages/Registration";
import AuthLayout from "./components/AuthLayout/AuthLayout";
import Login from "./pages/Login";
import AdminLayout from "./components/Admin/Layout/AdminLayout";
import Deshboard from "./pages/Admin/Deshboard";
import DivisionList from "./pages/Admin/Division";
import District from "./pages/Admin/District";
import Upazila from "./pages/Admin/Upazila";
import Ambassadors from "./pages/Ambassadors";
import Profile from "./pages/Profile";
import ProtectedRoute from "./utils/ProtectedRoute";
import NetaSelectForm from "./components/Layout/NetaSelectForm";
import UserList from "./pages/Admin/User";
import AmbassadorUsers from "./pages/Admin/AmbassadorUsers";
import ForgetPassword from "./pages/ForgetPassword";
import PasswordReset from "./pages/PasswordReset";
import CreateNeta from "./pages/Admin/Neta/Create";


export const RouteApp = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/neta/list",
        element: <NetaList />,
      },
      {
        path: "/neta/details/:id",
        element: <NetaDetails />,
      },
      {
        path: "/news/details/:id",
        element: <NewsDetails />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "/questions/list",
        element: <Questions />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "/answers/list",
        element: <Answers />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "/news/list",
        element: <News />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "/ambassadors/list",
        element: <Ambassadors />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "/petition",
        element: <Petition />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "/petition/details/:id",
        element: <PetitionDetails />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "/profile",
        element: (
          <ProtectedRoute>
            <Profile />
          </ProtectedRoute>
        ),
        errorElement: <ErrorBoundary />,
      },
      {
        path: "/neta/select",
        element: (
          <ProtectedRoute>
            <NetaSelectForm />
          </ProtectedRoute>
        ),
        errorElement: <ErrorBoundary />,
      },
    ],
  },
  {
    element: <AuthLayout />,
    children: [
      {
        path: "/registration",
        element: <Registration />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "/login",
        element: <Login />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "/forget-password",
        element: <ForgetPassword />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "/password-reset",
        element: <PasswordReset />,
        errorElement: <ErrorBoundary />,
      },
    ],
  },
  {
    element: <AdminLayout />,
    children: [
      {
        path: "/admin",
        element: <Deshboard />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "/admin/division",
        element: <DivisionList />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "/admin/district",
        element: <District />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "/admin/upazila",
        element: <Upazila />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "/admin/user",
        element: <UserList />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "/admin/user/ambassador",
        element: <AmbassadorUsers />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "/admin/neta/create",
        element: <CreateNeta />,
        errorElement: <ErrorBoundary />,
      },
    ],
  },
]);
