import React from 'react'

const NetaCreateForm = () => {
  return (
    <form>
      <div className="p-6">
        <div className="mb-4 flex flex-col xl:flex-row gap-6 items-end">
          <div className="w-full xl:w-1/2">
            <label className="mb-[10px] block text-black dark:text-white">
              name
            </label>
            <input
              //   value={props.name}
              //   onChange={(e) => props.setName(e.target.value)}
              type="text"
              placeholder="Enter division name"
              className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
            />
          </div>
          <div className="w-full xl:w-1/2">
            <label className="mb-[10px] block text-black dark:text-white">
              Designation
            </label>
            <input
              //   value={props.divisionData}
              //   onChange={(e) => props.setDivisionData(e.target.value)}
              type="text"
              placeholder="Enter division name"
              className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
            />
          </div>
          <div className="w-full xl:w-1/2">
            <label className="mb-[10px] block text-black dark:text-white">
              Email
            </label>
            <input
              //   value={props.divisionData}
              //   onChange={(e) => props.setDivisionData(e.target.value)}
              type="text"
              placeholder="Enter division name"
              className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
            />
          </div>
          {/* <div className="w-full xl:w-1/2">
            <button className="flex w-full xl:w-1/2 justify-center rounded bg-theme_color py-3.5 px-5 font-medium text-white">
              Submit
            </button>
          </div> */}
        </div>
        <div className="mb-4 flex flex-col xl:flex-row gap-6 items-end">
          <div className="w-full xl:w-1/2">
            <label className="mb-[10px] block text-black dark:text-white">
              Phone
            </label>
            <input
              //   value={props.name}
              //   onChange={(e) => props.setName(e.target.value)}
              type="text"
              placeholder="Enter division name"
              className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
            />
          </div>
          <div className="w-full xl:w-1/2">
            <label className="mb-[10px] block text-black dark:text-white">
              Birth day
            </label>
            <input
              //   value={props.divisionData}
              //   onChange={(e) => props.setDivisionData(e.target.value)}
              type="text"
              placeholder="Enter division name"
              className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
            />
          </div>
          <div className="w-full xl:w-1/2">
            <label className="mb-[10px] block text-black dark:text-white">
              Sex:
            </label>
            <input
              //   value={props.divisionData}
              //   onChange={(e) => props.setDivisionData(e.target.value)}
              type="text"
              placeholder="Enter division name"
              className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
            />
          </div>
        </div>
        <div className="mb-4 flex flex-col xl:flex-row gap-6 items-end">
          <div className="w-full xl:w-1/2">
            <label className="mb-[10px] block text-black dark:text-white">
              Education
            </label>
            <input
              //   value={props.name}
              //   onChange={(e) => props.setName(e.target.value)}
              type="text"
              placeholder="Enter division name"
              className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
            />
          </div>
          <div className="w-full xl:w-1/2">
            <label className="mb-[10px] block text-black dark:text-white">
              Profession
            </label>
            <input
              //   value={props.divisionData}
              //   onChange={(e) => props.setDivisionData(e.target.value)}
              type="text"
              placeholder="Enter division name"
              className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
            />
          </div>
          <div className="w-full xl:w-1/2">
            <label className="mb-[10px] block text-black dark:text-white">
              Present address::
            </label>
            <input
              //   value={props.divisionData}
              //   onChange={(e) => props.setDivisionData(e.target.value)}
              type="text"
              placeholder="Enter division name"
              className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
            />
          </div>
        </div>
        <div className="mb-4 flex flex-col xl:flex-row gap-6 items-end">
          <div className="w-full xl:w-1/2">
            <label className="mb-[10px] block text-black dark:text-white">
              Phone
            </label>
            <input
              //   value={props.name}
              //   onChange={(e) => props.setName(e.target.value)}
              type="text"
              placeholder="Enter division name"
              className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
            />
          </div>
          <div className="w-full xl:w-1/2">
            <label className="mb-[10px] block text-black dark:text-white">
              Birth day
            </label>
            <input
              //   value={props.divisionData}
              //   onChange={(e) => props.setDivisionData(e.target.value)}
              type="text"
              placeholder="Enter division name"
              className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
            />
          </div>
          <div className="w-full xl:w-1/2">
            <label className="mb-[10px] block text-black dark:text-white">
              Sex:
            </label>
            <input
              //   value={props.divisionData}
              //   onChange={(e) => props.setDivisionData(e.target.value)}
              type="text"
              placeholder="Enter division name"
              className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
            />
          </div>
        </div>
      </div>
    </form>
  );
}

export default NetaCreateForm