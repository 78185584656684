import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { NavigateFunction } from "react-router-dom";
import auth from "../../utils/auth";
import useAxios from "../../utils/useAxios";
import { API_URL } from "../../settings/config";
import { toast } from "react-toastify";

export const registration = createAsyncThunk(
  "registration",
  async (
    data: {
      first_name: string;
      last_name: string;
      phone_number: string;
      email: string;
      password: string;
      router: NavigateFunction;
    },
    thunkAPI
  ) => {
    const api = useAxios();
    try {
      const response = await api.post(`${API_URL}/v1/registration/`, {
        first_name: data.first_name,
        last_name: data.last_name,
        phone_number: data.phone_number,
        email: data.email,
        password: data.password,
      });
      data.router("/login");
      //   thunkAPI.dispatch(getDivisionList()); // You should dispatch getDivisionList here
      return response.data; // Return the data from the response
    } catch (error: any) {
      console.log(error?.message);

      if (error?.message) {
        const data = error?.message;
        console.log(data);

        const commonErr = [];
        if (data) {
          for (const [i, j] of Object.entries(data)) {
            console.log(`${i}: ${j}`);
            commonErr.push(`${j}`);
          }
        }
        return thunkAPI.rejectWithValue(commonErr || "An error occurred.");
      }

      return thunkAPI.rejectWithValue(error.message || "An error occurred.");
    }
  }
);

export const login = createAsyncThunk(
  "login",
  async (
    data: {
      identifier: string;
      password: string;
    },
    thunkAPI
  ) => {
    try {
      const api = useAxios();
      const response = await api.post(`${API_URL}/v1/login/`, data);
      const jwt = `Bearer ${response.data.data.access}`;

      auth.setToken(jwt);
      thunkAPI.dispatch(getUser()); // You should dispatch getDivisionList here
      return response.data; // Return the data from the response
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.message || "An error occurred.");
    }
  }
);

export const resetPassword = createAsyncThunk(
  "resetPassword",
  async (
    data: {
      identifier: string;
      router: any;
    },
    thunkAPI
  ) => {
    try {
      const api = useAxios();
      const response = await api.post(`${API_URL}/v1/password/reset/`, data);
      toast.success("Security code send successfully.");
      data.router(`/password-reset`);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.message || "An error occurred.");
    }
  }
);

export const passwordConfirm = createAsyncThunk(
  "passwordConfirm",
  async (
    data: {
      code: string;
      password: string;
      router: any;
    },
    thunkAPI
  ) => {
    try {
      const api = useAxios();
      const response = await api.post(
        `${API_URL}/v1/password/reset/confirm/`,
        data
      );
      toast.success("Your Password Reset successfully.");
      data.router(`/login`);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.message || "An error occurred.");
    }
  }
);

export const getUser = createAsyncThunk(
  "user/getUser",
  async (userData, { rejectWithValue }) => {
    let api = useAxios();
    try {
      const { data } = await api.get(`${API_URL}/v1/user/`);
      return data;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const getAmbassadorList = createAsyncThunk(
  "user/ambassadorList",
  async (paramsData: any, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`${API_URL}/v1/ambassadors/`, {
        params: paramsData,
      });
      return data;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const getUserProfile = createAsyncThunk(
  "user/details",
  async (userData, { rejectWithValue }) => {
    let api = useAxios();
    try {
      const { data } = await api.get(`${API_URL}/v1/user-details`);
      console.log({data});
      
      return data;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const updateUserProfile = createAsyncThunk(
  "user/details-update",
  async (
    data: any,
    thunkAPI
  ) => {
    try {
      let api = useAxios();
      const response = await api.post(
        `${API_URL}/v1/user-details-update/`,
        data
      );
      thunkAPI.dispatch(getUser());
      window.location.reload();
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const followNeta = createAsyncThunk(
  "user/neta-follow",
  async (
    data: {
      seat: number;
      district: number;
      upazila: number;
      router: any;
    },
    thunkAPI
  ) => {
    try {
      let api = useAxios();
      const response = await api.post(`${API_URL}/v1/member/select/`, {
        seat: data.seat,
        district: data.district,
        upazila: data.upazila,
      });
      thunkAPI.dispatch(getUser());
      if (response.data.member)
        data.router(`/neta/details/${response.data.member}`);

      return response.data.message;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const getAdminAmbassadorList = createAsyncThunk(
  "user/adminAmbassadorList",
  async (paramsData: any, { rejectWithValue }) => {
    try {
      let api = useAxios();
      const { data }: any = await api.get(`${API_URL}/v1/admin-ambassadors/`, {
        params: paramsData,
      });
      const results = data?.results?.map((item: any) => ({
        ...item,
        status: item.role === "AMBASSADORS" ? true : false,
      }));
      const final = { ...data, results };
      return final;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);
