import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { resetPassword } from "../../store/API/UserApis";
import { useAppDispatch } from "../../store/hooks";

const ForgetPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const onSubmit = handleSubmit((data) => {
    dispatch(
      resetPassword({
        identifier: data.identifier,
        router: navigate,
      })
    );
  });
  return (
    <section className="login_area bg-[#fafbff] lg:py-20 sm:py-10 py-8">
      <div className="container-fluid mx-auto">
        <div className="max-w-[585px] w-full mx-auto p-7 bg-white rounded-[10px] outline outline-1 outline-gray-300">
          <div className="mb-9">
            <h4 className="flex items-center justify-center text-gray-900 mb-1">
              Forgot
              <span className="text-green-500 ml-1 text-lg font-bold">your password</span>
            </h4>
            <h4 className="flex text-sm items-center justify-center text-gray-900 mb-1">
              Enter your email/phone number and we'll send you a OTP to reset
              your password
            </h4>
          </div>
          <form onSubmit={onSubmit}>
            <div className="mb-4 relative w-full bg-white group rounded-md">
              <input
                {...register("identifier", { required: true })}
                id="email"
                type="email"
                className="block p-4 rounded-md w-full text-xs font-normal text-gray-900 placeholder:text-gray-700 bg-transparent outline outline-1 outline-gray-300 appearance-none focus:outline-green-500 peer"
                placeholder=" "
                required
              />
              <label
                htmlFor="email"
                className="ml-[14px] z-[1] flex items-center px-1 rounded-[3px] peer-focus:font-medium absolute bg-white text-xs font-normal text-gray-700 duration-300 transform -translate-y-[20px] scale-75 top-3 peer-focus:z-10 origin-[0] peer peer-disabled:bg-green-500 peer-focus:left-0 peer-focus:text-green-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-[4.5px] peer-focus:scale-75 peer-focus:-translate-y-[20px]"
              >
                Email or Phone <span className="text-red-500">&nbsp; *</span>
              </label>
              {errors?.identifier && (
                <p className="text-red-500 text-start">
                  This field is required.
                </p>
              )}
            </div>
            {/* {error && (
              <div className="mt-4 space-y-3">
                <div className="p-3 bg-red-100 text-red-700 border border-red-300 rounded-md items-center gap-3">
                  <p className="flex-grow font-medium whitespace-pre-line">
                    {error}
                  </p>
                </div>
              </div>
            )} */}
            <button
              type="submit"
              className="w-full px-8 py-4 mt-9 rounded-md font-medium text-base flex items-center justify-center bg-green-500 text-white hover:bg-green-600 transition-colors duration-200 mr-4"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default ForgetPassword;
