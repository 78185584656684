import React from "react";
import Select from "react-select";


interface CommonSelectProps {
  selectOptions: { value: number; label: string }[];
  selectData?: { value: number; label: string } | null;
  setSelectData: (data: { value: number; label: string }) => void;
  handleSelectChange: (data: any) => void;
  placeholder: string;
  searchable?: boolean;
  clearable?: boolean;
}

const SelectComponent: React.FC<CommonSelectProps> = ({
  selectOptions,
  selectData,
  handleSelectChange,
  placeholder,
  searchable=false,
  clearable=true,
}) => {
  
  return (
    <Select
      className="bg-transparent h-10 p-0.5 w-full text-black text-sm appearance-none focus:outline-none"
      classNamePrefix="select"
      aria-label="Default select example"
      key={`my_unique_select_key__${selectOptions.find(
        (option) => option.value === selectData?.value
      )}`}
      isClearable={clearable}
      isSearchable={searchable}
      placeholder={placeholder}
      value={selectOptions.find((option) => option.value === selectData?.value)}
      onChange={handleSelectChange}
      options={selectOptions}
      instanceId="common-select"
    />
  );
};

export default SelectComponent;
