import { Link } from "react-router-dom";
import { SingleAmbassadorType } from "../../models";
import { useAppSelector } from "../../store/hooks";

const AmbassadorsList = () => {
  const { ambassadorList } = useAppSelector((state) => state.auth);
  console.log(ambassadorList);

  return (
    <section className="neta_list bg-[#fafbff] lg:py-10 py-8">
      <div className="container-fluid mx-auto">
        <div className="w-full">
          <div>
            <div className="flex flex-col gap-3 bg-white rounded-md shadow-theme_shadow">
              {ambassadorList?.results?.map((item: SingleAmbassadorType) => (
                <div
                  key={item?.id}
                  className="flex sm:flex-row flex-col sm:items-center gap-5 p-4 border-b border-dashed"
                >
                  <div className="mp_thumb h-[80px] w-[80px]">
                    <img
                      className="w-full h-full rounded-full border-2 border-green-500"
                      src={item?.user_details?.profile_pic}
                      alt=""
                    />
                  </div>
                  <div className="mp_content md:w-[calc(100%_-_100px)] w-full">
                    <div className="flex xl:flex-row flex-col items-start justify-between sm:gap-3 gap-1">
                      <div className="am_titile">
                        <a href="amb-profile.html">
                          <h4 className="text-black md:text-xl text-lg font-medium hover:text-red-500">
                            {item?.first_name} {item?.last_name}
                          </h4>
                        </a>
                        <span className="text-green-500 text-base font-normal">
                          Ambassadors
                        </span>
                        <div className="flex items-center gap-2">
                          <span className="text-black text-sm font-normal">
                            Phone:
                          </span>
                          <span className="text-[#4a4a4a] text-sm font-normal">
                            {item?.phone_number}
                          </span>
                        </div>

                        <div className="flex flex-col md:flex-row md:items-center gap-2">
                          {item?.member?.map((mem) => (
                            <Link
                              to={`/neta/details/${mem?.id}`}
                              key={mem?.id}
                              className="text-green-500 text-base font-medium"
                            >
                              {mem?.name}
                            </Link>
                          ))}
                        </div>
                      </div>
                      <div className="mp_location flex items-center gap-2">
                        <i>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-5 h-5"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                            />
                          </svg>
                        </i>
                        <span className="text-[#4a4a4a] text-base font-normal">
                          {item?.user_details?.seat?.name}
                        </span>
                      </div>
                      <div className="mp_party">
                        <span className="text-[#4a4a4a] text-base font-normal">
                          {item?.user_details?.political_party}
                        </span>
                      </div>
                      {item?.user_details?.facebook_link && (
                        <div className="mp_wealth">
                          <Link
                            target="_blank"
                            to={`https://${item?.user_details?.facebook_link}`}
                          >
                            <img
                              src="https://img.icons8.com/ios-filled/50/12E856/facebook--v1.png"
                              alt="facebook--v1"
                            />
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AmbassadorsList;
