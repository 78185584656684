import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { login } from "../../store/API/UserApis";
import { ToastContainer } from "react-toastify";

const Login = () => {
  const { user, error } = useAppSelector((state) => state.auth);
  const [searchParams] = useSearchParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onSubmit = handleSubmit((data) => {
    dispatch(
      login({
        identifier: data.identifier,
        password: data.password,
      })
    );
  });

  useEffect(() => {
    if (user?.id && searchParams.get("next")) {
      const path = searchParams?.get("next");
      if (path) navigate(path);
    } else if (user?.id) {
      navigate("/");
    }
  }, [user, navigate, searchParams]);

  return (
    <section className="login_area bg-[#fafbff] lg:py-20 sm:py-10 py-8">
      <ToastContainer />
      <div className="container-fluid mx-auto">
        <div className="max-w-[585px] w-full mx-auto p-7 bg-white rounded-[10px] outline outline-1 outline-gray-300">
          <div className="mb-9">
            <h4 className="flex items-center justify-center text-gray-900 mb-1">
              Welcome to
              <span className="bg-gradient-to-r from-green-500 to-red-400 bg-clip-text fill-transparent ml-1">
                NeTa
              </span>
            </h4>
            <p className="font-normal text-gray-700 text-center">
              Don’t have an account?{" "}
              <Link to="/registration" className="text-green-500">
                Register Now!
              </Link>
            </p>
          </div>
          <form onSubmit={onSubmit}>
            <div className="mb-4 relative w-full bg-white group rounded-md">
              <input
                {...register("identifier", { required: true })}
                id="email"
                type="text"
                className="block p-4 rounded-md w-full text-xs font-normal text-gray-900 placeholder:text-gray-700 bg-transparent outline outline-1 outline-gray-300 appearance-none focus:outline-green-500 peer"
                placeholder=" "
                required
              />
              <label
                htmlFor="email"
                className="ml-[14px] z-[1] flex items-center px-1 rounded-[3px] peer-focus:font-medium absolute bg-white text-xs font-normal text-gray-700 duration-300 transform -translate-y-[20px] scale-75 top-3 peer-focus:z-10 origin-[0] peer peer-disabled:bg-green-500 peer-focus:left-0 peer-focus:text-green-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-[4.5px] peer-focus:scale-75 peer-focus:-translate-y-[20px]"
              >
                Email or Phone <span className="text-red-500">&nbsp; *</span>
              </label>
              {errors?.identifier && (
                <p className="text-red-500 text-start">
                  This field is required.
                </p>
              )}
            </div>
            <div className="mb-4 relative w-full bg-white group rounded-md">
              <input
                {...register("password", { required: true })}
                id="password"
                type="password"
                className="block p-4 rounded-md w-full text-xs font-normal text-gray-900 placeholder:text-gray-700 bg-transparent outline outline-1 outline-gray-300 appearance-none focus:outline-green-500 peer"
                placeholder=" "
                required
              />
              <label
                htmlFor="password"
                className="ml-[14px] z-[1] flex items-center px-1 rounded-[3px] peer-focus:font-medium absolute bg-white text-xs font-normal text-gray-700 duration-300 transform -translate-y-[20px] scale-75 top-3 peer-focus:z-10 origin-[0] peer peer-disabled:bg-green-500 peer-focus:left-0 peer-focus:text-green-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-[4.5px] peer-focus:scale-75 peer-focus:-translate-y-[20px]"
              >
                Password <span className="text-red-500">&nbsp; *</span>
              </label>
              {errors?.password && (
                <p className="text-red-500 text-start">Password is required.</p>
              )}
            </div>
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  value=""
                  id="Remember"
                  type="checkbox"
                  className="appearance-none h-[13.5px] w-[13.5px] border border-gray-700 rounded bg-white checkbox-checked] bg-cover bg-no-repeat bg-center checked:border-green-500 focus:outline-none mr-2 cursor-pointer"
                />
                <label htmlFor="Remember" className="font-normal text-gray-700">
                  Remember me?
                </label>
              </div>
              <Link to="/forget-password" className="font-normal text-red-500">
                Forget password?
              </Link>
            </div>
            {error && (
              <div className="mt-4 space-y-3">
                <div className="p-3 bg-red-100 text-red-700 border border-red-300 rounded-md items-center gap-3">
                  <p className="flex-grow font-medium whitespace-pre-line">
                    {error}
                  </p>
                </div>
              </div>
            )}
            <button
              type="submit"
              className="w-full px-8 py-4 mt-9 rounded-md font-medium text-base flex items-center justify-center bg-green-500 text-white hover:bg-green-600 transition-colors duration-200 mr-4"
            >
              Submit
            </button>
            {/* <div className="py-6 text-center">
              <p className="font-normal text-gray-700">OR</p>
            </div>
            <div>
              <button
                type="button"
                className="w-full px-8 py-[14px] rounded-md font-medium text-base flex items-center justify-center hover:bg-green-25 bg-white border border-gray-300 text-gray-900 hover:text-green-500 transition-colors duration-200"
              >
                <img
                  alt="metamask"
                  className="mr-3 w-7 h-7"
                  src="../src/img/login/metamask.png"
                />
                Sign in with Meta Mask
              </button>
              <button
                type="button"
                className="w-full px-8 py-[14px] rounded-md font-medium text-base flex items-center justify-center hover:bg-green-25 bg-white border border-gray-300 text-gray-900 hover:text-green-500 transition-colors duration-200 my-3"
              >
                <img
                  alt="facebook"
                  className="mr-3 w-7 h-7"
                  src="../src/img/login/facebook.png"
                />
                Sign in with Facebook
              </button>
              <button
                type="button"
                className="w-full px-8 py-[14px] rounded-md font-medium text-base flex items-center justify-center hover:bg-green-25 bg-white border border-gray-300 text-gray-900 hover:text-green-500 transition-colors duration-200"
              >
                <img
                  alt="Google"
                  className="mr-3 w-7 h-7"
                  src="../src/img/login/Google.png"
                />
                Sign in with Google
              </button>
            </div> */}
          </form>
        </div>
      </div>
    </section>
  );
};

export default Login;
