import { Link } from "react-router-dom";
import LogoImage from "../../images/logo/logoImg.jpg";

const AuthLayoutHeader = () => {
  return (
    <header className="header_area bg-white shadow-theme_shadow py-4 sticky top-0 z-[999]">
      <div className="container-fluid mx-auto">
        <div className="flex justify-center">
          <div className="brand_logo">
            <Link
              to="/"
              className="flex items-center gap-1 text-2xl font-semibold font-poppins group"
            >
              <img
                src={LogoImage}
                alt=""
                className="h-14 min-w-36 overflow-hidden"
              />
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
}

export default AuthLayoutHeader